<script setup>
    import {menuStore} from '../store/menuStore'

    const menu = menuStore();

</script>
<template>
    <div class="head">
        <a class="headfont left aaSlideInDown1s"  href="/en">EN</a>
        <div class="headfont right aaSlideInDown1s" @click="menu.doMenuOpen()">菜单</div>
    </div>
</template>
