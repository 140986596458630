import {Curtains, Plane, Vec2,PingPongPlane, Vec3 } from 'curtainsjs';
let ih = 0;
let nh = 0;
class ssp {



  constructor() {
    this.updateVelocity = !1,
      this.isEnabled = !1,
      this.onScreenResize = this.onScreenResize.bind(this),
      this.onMouseMove = this.onMouseMove.bind(this),
      this.hasGL = !0,
      this.curtains = new Curtains({
        production: !0,
        watchScroll: !1,
        container: "canvas",
        antialias: !1,
        pixelRatio: Math.min(1.5, window.devicePixelRatio)
      }).onError(() => {
          this.hasGL = !1,
            document.body.classList.add("no-curtains"),
            document.querySelector(".curtain__container").classList.add("curtain__container--is-hidden")
        }
      ).onContextLost(() => {
          this.curtains.restoreContext()
        }
      );
    const e = window.innerWidth
      , t = window.innerHeight;
    this.mouse = new Vec2(e / 2, t / 2),
      this.lastMouse = this.mouse.clone(),
      this.velocity = new Vec2(),
      this.targetX = this.mouse.x,
      this.targetY = this.mouse.y,
      this.currentX = this.targetX,
      this.currentY = this.targetY,
      window.addEventListener("resize", this.onScreenResize),
      this.planeElement = document.querySelector(".curtain__container");
    const r = {
      sampler: "uFlowMap",
      vertexShader: "\n#ifdef GL_FRAGMENT_PRECISION_HIGH\nprecision highp float;\n#else\nprecision mediump float;\n#endif\n\n// default mandatory variables\nattribute vec3 aVertexPosition;\nattribute vec2 aTextureCoord;\n\nuniform mat4 uMVMatrix;\nuniform mat4 uPMatrix;\n\n// custom variables\nvarying vec3 vVertexPosition;\nvarying vec2 vTextureCoord;\n\nvoid main() {\n\n    vec3 vertexPosition = aVertexPosition;\n\n    gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);\n\n    // varyings\n    vTextureCoord = aTextureCoord;\n    vVertexPosition = vertexPosition;\n}\n",
      fragmentShader: "\n#ifdef GL_FRAGMENT_PRECISION_HIGH\nprecision highp float;\n#else\nprecision mediump float;\n#endif\n\nvarying vec3 vVertexPosition;\nvarying vec2 vTextureCoord;\n\nuniform sampler2D uFlowMap;\n\nuniform vec2 uMousePosition;\nuniform float uFalloff;\nuniform float uAlpha;\nuniform float uDissipation;\nuniform float uCursorGrow;\n\nuniform vec2 uVelocity;\nuniform float uAspect;\n\nvoid main() {\n    vec2 textCoords = vTextureCoord;\n    \n    \n    /*** comment this whole block for a regular mouse flow effect ***/\n    \n    // convert to -1 -> 1\n    textCoords = textCoords * 2.0 - 1.0;\n    \n    // make the cursor grow with time\n    textCoords /= uCursorGrow;\n    // adjust cursor position based on its growth\n    textCoords += uCursorGrow * uMousePosition / (1.0 / (uCursorGrow - 1.0) * pow(uCursorGrow, 2.0));\n\n    // convert back to 0 -> 1\n    textCoords = (textCoords + 1.0) / 2.0;\n    \n    /*** end of whole block commenting for a regular mouse flow effect ***/\n\n\n    vec4 color = texture2D(uFlowMap, textCoords) * uDissipation;\n     color = vec4(0.0, 0.0, 0.0, 1.0) * uDissipation;\n\n    vec2 mouseTexPos = (uMousePosition + 1.0) * 0.5;\n    vec2 cursor = vTextureCoord - mouseTexPos;\n    cursor.x *= uAspect;\n\n    vec3 stamp = vec3(uVelocity * vec2(1.0, -1.0), 1.0 - pow(1.0 - min(1.0, length(uVelocity)), 3.0));\n    float falloff = smoothstep(uFalloff, 0.0, length(cursor)) * uAlpha;\n    color.rgb = mix(color.rgb, stamp, vec3(falloff));\n\n    // handle premultiply alpha\n    color.rgb = color.rgb * color.a;\n\n    gl_FragColor = color;\n}\n",
      texturesOptions: {
        floatingPoint: "half-float"
      },
      uniforms: {
        mousePosition: {
          name: "uMousePosition",
          type: "2f",
          value: this.mouse
        },
        fallOff: {
          name: "uFalloff",
          type: "1f",
          value: .85
        },
        cursorGrow: {
          name: "uCursorGrow",
          type: "1f",
          value: .85
        },
        alpha: {
          name: "uAlpha",
          type: "1f",
          value: 1
        },
        dissipation: {
          name: "uDissipation",
          type: "1f",
          value: .2
        },
        velocity: {
          name: "uVelocity",
          type: "2f",
          value: this.velocity
        },
        aspect: {
          name: "uAspect",
          type: "1f",
          value: e / t
        }
      }
    };
    // console.log('1111', this.curtains, this.planeElement, r)
    this.flowMapPlane = new PingPongPlane(this.curtains, this.planeElement, r),
      this.flowMapPlane.onRender(() => {
          this.isEnabled && (this.flowMapPlane.uniforms.mousePosition.value = this.flowMapPlane.mouseToPlaneCoords(this.mouse),
          this.updateVelocity || this.velocity.set(this.curtains.lerp(this.velocity.x, 0, .5), this.curtains.lerp(this.velocity.y, 0, .5)),
            this.updateVelocity = !1,
            this.flowMapPlane.uniforms.velocity.value = new Vec2(this.curtains.lerp(this.velocity.x, 0, .1), this.curtains.lerp(this.velocity.y, 0, .1)))
        }
      ),
      this.plane = new Plane(this.curtains, this.planeElement, {
        vertexShader: "\n                #ifdef GL_FRAGMENT_PRECISION_HIGH\n                precision highp float;\n                #else\n                precision mediump float;\n                #endif\n            \n                // default mandatory variables\n                attribute vec3 aVertexPosition;\n                attribute vec2 aTextureCoord;\n            \n                uniform mat4 uMVMatrix;\n                uniform mat4 uPMatrix;\n            \n                uniform mat4 planeTextureMatrix;\n            \n                // custom variables\n                varying vec3 vVertexPosition;\n                varying vec2 vPlaneTextureCoord;\n                varying vec2 vTextureCoord;\n            \n                void main() {\n            \n                    vec3 vertexPosition = aVertexPosition;\n            \n                    gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);\n            \n                    // varyings\n                    vTextureCoord = aTextureCoord;\n                    vPlaneTextureCoord = (planeTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;\n                    vVertexPosition = vertexPosition;\n                }\n            ",
        fragmentShader: "\n                #ifdef GL_FRAGMENT_PRECISION_HIGH\n                precision highp float;\n                #else\n                precision mediump float;\n                #endif\n            \n                varying vec3 vVertexPosition;\n                varying vec2 vPlaneTextureCoord;\n                varying vec2 vTextureCoord;\n            \n                uniform sampler2D planeTexture;\n                uniform sampler2D uFlowTexture;\n            \n                void main() {\n                    // our flowmap\n                    vec4 flowTexture = texture2D(uFlowTexture, vTextureCoord);\n            \n                    // distort our image texture based on the flowmap values\n                    vec2 distortedCoords = vPlaneTextureCoord;\n                    distortedCoords -= flowTexture.xy * 0.1;\n            \n                    // get our final texture based on the displaced coords\n                    vec4 texture = texture2D(planeTexture, distortedCoords);\n            \n                    // switch between this 2 lines to see what we have drawn onto our flowmap\n                    //gl_FragColor = flowTexture;\n                    gl_FragColor = texture;\n                }\n            "
      }).onReady(() => {
          const e = document.querySelector(".curtain__image");
          ih = e.naturalWidth,
            nh = e.naturalHeight,
            e.classList.add("curtain__image--is-hidden"),
            this.onScreenResize()
        }
      ),
      this.plane.createTexture({
        sampler: "uFlowTexture",
        fromTexture: this.flowMapPlane.getTexture()
      }),
      this.onScreenResize()
  }

  onScreenResize() {
    const e = ih
      , t = e / nh
      , r = Math.min(e, .95 * window.innerWidth / e * e)
      , i = r / t
      , n = document.querySelector(".curtain__container");
    if(n) {
      n.style.width = r + "px",
        n.style.height = i + "px";
      const s = window.innerWidth < 812 ? 12 : 18
        , o = (1 - r / ih) * s;
      this.plane.setRelativeTranslation(new Vec3(o, o, 0)),
        this.curtains.resize()
    }
  }

  onMouseMove(e) {
    if (this.lastMouse.copy(this.mouse),
      e.targetTouches) {
      // console.log('mouse move1', e);
      this.mouse.set(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
    }else if (new Vec2()) {
      // console.log('mouse move2',e );
      const t = .2;
      this.targetX = e.clientX,
        this.targetY = e.clientY,
        this.currentX += (this.targetX - this.currentX) * t,
        this.currentY += (this.targetY - this.currentY) * t,
        this.mouse.set(this.currentX, this.currentY)
    } else
      this.mouse.set(e.clientX, e.clientY);
    this.velocity.set((this.mouse.x - this.lastMouse.x) / 16, (this.mouse.y - this.lastMouse.y) / 16),
      this.updateVelocity = !0
  }

  getCanvas() {
    return this.curtains && this.hasGL ? document.getElementById("marx-logo-container") : null
  }

  enable(e) {
    this.isEnabled = e,
    this.curtains && (e ? (this.curtains.enableDrawing(),
      window.addEventListener("mousemove", this.onMouseMove),
      window.addEventListener("touchmove", this.onMouseMove, {
        passive: !0
      })) : (this.curtains.disableDrawing(),
      window.removeEventListener("mousemove", this.onMouseMove),
      window.removeEventListener("touchmove", this.onMouseMove)))
  }
  clear(){
    this.curtains.disableDrawing(),
      window.removeEventListener("mousemove", this.onMouseMove),
      window.removeEventListener("touchmove", this.onMouseMove),
      window.addEventListener("resize", this.onScreenResize),
      this.curtains.clear();
  }
}
export default ssp;
