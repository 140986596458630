<template>
    <div class="c-bottom">
        ©NOONE
    </div>
</template>
<style lang="less">

    .c-bottom {
      font-weight: 300;
      width: 100%;
      font-size: 14px;
      text-align: center;
      font-family: TaipeiSansTCBeta;
      margin-top: 148px;
      padding-bottom: 24.5px;
    }
    @media screen and (max-width: 720px){
      .c-bottom{
          margin-top: 58px;
          height: auto;
          padding-bottom: 24.5px;
      }
    }
</style>
