import { defineStore } from 'pinia'
import axios from 'axios'

export const menuStore = defineStore("menu",{
  state: ()=>{
    return {
      open: false,
      fromBtn: false,
      pdOpen: false,
      pDetail:null,
      wpUrl:"http://test.madebynoone.com/wp",//"http://113.219.210.224:10100"   "/wp"
      workDate:[],//作品
      WeDate:[],//WE大会
      scienceDate:[],//科学小会
      homeData:[],//首页
      touchData:[],//接触
    }
  },
  getters: {

  },
  actions: {
    doMenuOpen(){
      this.open = true
      this.fromBtn = false;
    },
    doMenuClose(){
      this.open = false;
      this.fromBtn = true;
    },
    openPd(item){
      this.pdOpen = true;
      //console.log("menuitem,",item)
      this.pDetail=item;
    },
    closePd(){
      this.pdOpen = false;
    },
    //作品、首页数据查询
    loadData(){
      this.workDate = window.rawData.workDate;
      this.WeDate = window.rawData.WeDate;
      this.scienceDate = window.rawData.scienceDate;
      this.homeData = window.rawData.homeData;
      this.touchData = window.rawData.touchData;
    },
  }
})
