<template>
    <div class="pd list-page-c" :class="{vplaying: pdData.playing}">
        <div class="head" v-if="!pdData.playing">
            <a class="headfont left aaSlideInDown1s" href="/en">EN</a>
            <div class="headfont right aaSlideInDown1s" @click="doCloseDetail()">关闭</div>
        </div>
        <div class="Sctitle aaSlideInUp1s">
            作品<span>, </span>
        </div>
        <div class="Sccontent aaSlideInUp2s">
            <div class="Scvideo">
                <div class="video-d">
                    <div class="videoleft"  @click="pdData.playing = true">
                        <!--                        <video class="imgstyle" :src="item.vpath" :poster="item.vposter">-->
                        <!--                        </video>-->
                        <img :src="menu.pDetail.vposter" class="imgstyle" />
                      <img src="../assets/img/三角形.png" class="imgSanJiao"/>
                    </div>
                    <div class="c-detail">
                        <div class="desc">
                            <div class="title">
                                <div class="m-title">
                                  <span class="qtqt">“</span>{{menu.pDetail.mainTitle}}
                                  <span class="qtqt" v-if="!menu.pDetail.subTitle">”</span>
                                </div>
                                <div class="s-title">{{menu.pDetail.subTitle}}<span class="qtqt" v-if="menu.pDetail.subTitle">”</span>
                                </div>
                            </div>
                            <div class="time">{{menu.pDetail.time}}</div>
                        </div>
                        <div :class="[menu.pDetail.desc!==''?'line':'']" ></div>
                        <div class="detail">
                            {{menu.pDetail.desc}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Bottom></Bottom>
        <transition mode="out-in" enter-active-class="aaSlideInUp"
                    leave-active-class="aaSlideOutDown">
        <div class="play-detail" v-if="pdData.playing">
            <video controls :src="menu.pDetail.vpath" autoplay></video>
            <div class="head">
                <div class="headfont left aaSlideInDown1s">EN</div>
                <div class="headfont center">{{menu.pDetail.mainTitle}}</div>
                <div class="headfont right aaSlideInDown1s" @click="pdData.playing = false">关闭</div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script setup>
  import {reactive} from 'vue'
  import {menuStore} from "@/store/menuStore";
  import Bottom from '@/components/Bottom'
  import axios from "axios";

  const menu = menuStore();

  const pdData = reactive({
    item: {
      vposter: "http://1257956733.vod2.myqcloud.com/dc9f4012vodcq1257956733/e0ec1fde387702293773109678/387702293773486962.png",
      vpath: "http://1257956733.vod2.myqcloud.com/b3892dbavodtranscq1257956733/e0ec1fde387702293773109678/v.f40.mp4",
      mainTitle: "腾讯科学小会开场片",
      subTitle: "",
      desc: "腾讯集团首支投放于中央电视台的品牌广告",
      time: "2019"
    },
    playing: false
  })

  const doCloseDetail = () => {
    menu.closePd();
  }

</script>

<style lang="less">
    .pd {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh - 46px);
        background-color: #47DD0B;
        padding-top: 46px;
        overflow-y: auto;
        z-index: 200;
        &.vplaying{
            overflow: hidden;
        }

        .play-detail{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            video{
                width: 100%;
                height: 100%;
            }
            .head{
                .headfont{
                    color: #fff;
                    .right{
                        color: #fff;
                    }
                    .left{
                        color: #fff;
                    }
                }
            }
        }

        .Sctitle {
            margin-top: 45px;
            margin-right: 160px;
            font-family: TaipeiSansTCBeta;
            font-weight: 700;
            font-size: 200px;
            text-align: right;
            line-height: 195px;
          color: #000000;

            span {
              font-family: system-ui;
              font-weight: 500;
            }
        }

        .Sccontent {
            color: #000000ff;
            font-weight: 400;
            font-family: TaipeiSansTCBetaRegular;
            font-size: 18px;
            text-align: left;
            line-height: 36px;
            letter-spacing: 2px;
            margin-right: 8.5%;
            margin-left: 8.5%;
            margin-top: 148px;
          min-height: calc(100vh - 644px);

            .Scvideo {
                margin-top: 163px;
            }
        }

        .Scbottom {
            font-weight: 300;
            width: 100%;
            font-size: 14px;
            text-align: center;
            font-family: TaipeiSansTCBeta;
            height: 64px;
        }

        .video-d {
            display: flex;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 16.7vw;
            }

            .imgstyle {
                width: 100%;
              display: block;
            }
          .imgSanJiao{
            position:absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
          }

            .desc {
                /* display: inline-block;*/
                color: #000000ff;
                font-weight: 400;
                font-size: 18px;
                text-align: left;
                letter-spacing: 2px;
                margin-right: 6%;

                .title {
                }

                &.to-left {
                    order: -1;
                    margin-left: 6%;
                }

                .time {
                    margin-top: 45px;
                }
            }

            .detail{
                margin-right: 6%;
                margin-top: 68px;
            }

            .videoleft {
                max-width: 1016px;
                cursor: pointer;
              width: 53%;
              position: relative
            }
            .c-detail{
              width: 30%;
            }
            .videoright {
                max-width: 1016px;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .pd {
            min-height: 100vh;

            .Sctitle {
                margin-right: 20px;
                font-size: 60px;
                line-height: 60px;
              color: #000000;
            }

            .Sccontent {
                margin-right: 0;
                margin-left: 0;
                font-size: 12px;
                margin-top: 60px;

                .Scvideo {
                    margin-top: 95px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .video-d {
                margin-left: 0;
                margin-right: 0;
                flex-flow: column;

                &:not(:first-child) {
                    margin-top: 37.5px;
                }

                .videoleft, .videoright {
                    width: 100%;
                }
              .c-detail{
                width: auto;
                padding-left: 20px;
                padding-right: 20px;
              }
                .desc {
                    margin: 0;
                    /*padding-left: 20px;*/
                    padding-right: 20px;
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;

                    &.to-left {
                        order: initial;
                        margin: 0;
                    }

                    .title {
                        display: flex;
                        justify-content: space-between;

                        .qtqt {
                            display: none;
                        }
                    }

                    .time {
                        margin-top: 0;
                    }
                }
              .line{
                margin-top: 46px;
                width: 35px;
                height: 2px;
                background-color: #000000;
              }
              .detail{
                margin-right: 6%;
                margin-top: 22px;
              }

            }
        }
    }
</style>
