<template>
    <div class="continer pc" v-if="ifProps.mimages.length">
        <transition mode="out-in" v-for="(item,index) in ifProps.images" enter-active-class="aaFadeIn"
                    leave-active-class="aaFadeOut">
            <img :src="item" v-if="hData.showIndex == index" >
        </transition>
    </div>
    <div class="continer m" v-if="ifProps.mimages.length">
        <transition mode="out-in" v-for="(item,index) in ifProps.mimages" enter-active-class="aaFadeIn"
                    leave-active-class="aaFadeOut">
            <img :src="item" v-if="hData.showIndex == index" >
        </transition>
    </div>
    <div class="continer pc-m" v-else>
        <transition mode="out-in" v-for="(item,index) in ifProps.images" enter-active-class="aaFadeIn"
                    leave-active-class="aaFadeOut">
          <a>
            <img :src="item" v-if="hData.showIndex == index" >
          </a>
        </transition>
    </div>
</template>
<script setup>
  import {reactive,defineProps, onMounted, onUnmounted} from 'vue'

  const ifProps = defineProps({
    images:{
      type:Array,
      default:[]
    },
    mimages:{
      type:Array,
      default:[]
    }
  })

  const hData = reactive({
    showIndex: 0,
    timer: null
  })


  function startSwitch(){
    if(hData.timer){
      clearTimeout(hData.timer)
    }
    hData.timer = setTimeout(function () {
     // console.log('fade timer');
      if(hData.showIndex < ifProps.images.length-1){
        hData.showIndex= hData.showIndex+1;
      }else{
        hData.showIndex=0;
      }
      startSwitch();
    }, 5000)
  }

  onMounted(()=>{
    startSwitch()
  })

  onUnmounted(()=>{
    if(hData.timer){
      clearTimeout(hData.timer)
    }
  })
</script>

<style scope lang="less">
    .continer {
      width: 100%;
      min-height: calc(100vw / 1920 * 969);
      position: relative;
      overflow: hidden;
      background-color: #ECE8E7;

      img {
        /*float: left;*/
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }

      &.pc {
        display: block;
      }

      &.m {
        display: none;
      }

      &.pc-m {
        a img {
          filter: grayscale(1);
        }

        a:hover img {
          filter: none;
        }
      }
    }
    @media screen and (max-width: 720px) {
      .continer {
        min-height: calc(100vw / 750 * 1420);

        img {
          width: 100vw;
          height: auto;
        }

        &.pc {
          display: none;
        }

        &.m {
          display: block;
        }

        &.pc-m {
          a img {
            filter: none;
          }
        }
      }
    }
</style>
