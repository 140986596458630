<script setup>
  import Menu from "@/components/Menu";
  import ProductDetail from '@/components/ProductDetail'
  import {menuStore} from "@/store/menuStore";
  const menu = menuStore();
  const created=()=>{
    menu.loadData();
  }
  created();
</script>
<template>
  <div class="cc-main aaZoomIn" :class="{open: menu.open}">

    <transition mode="out-in" enter-active-class="aaSlideInDown"
                leave-active-class="aaSlideOutUp">
      <Menu v-if="menu.open"></Menu>
    </transition>
  <router-view/>

    <transition mode="out-in" enter-active-class="aaSlideInUp"
                leave-active-class="aaSlideOutDown">
      <ProductDetail v-if="menu.pdOpen">

      </ProductDetail>
    </transition>

  </div>

</template>
<style>
  .aaZoomIn{
    animation: zoomIn;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease-in;
    -webkit-animation-timing-function: ease-in;
  }
  .aaFadeInDown{
    animation: flipInX;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeInUp{
    animation: fadeInUp;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeOutDown{
    animation: fadeOutDown;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeInRight{
    animation: fadeInRight;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeInLeft{
    animation: fadeInLeft;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeOutRight{
    animation: fadeOutRight;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeOutLeft{
    animation: fadeOutLeft;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaFadeOutUp{
    animation: flipOutX;
    animate-duration: 200ms;
    -webkit-animation-duration: 200ms;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }

  .aaSlideInDown{
    animation: slideInDown;
    animate-duration: 500ms;
    -webkit-animation-duration: 500ms;
    animation-timing-function: ease-in;
    -webkit-animation-timing-function: ease-in;
  }


  .aaSlideInDown1s{
    animation: fadeInDownBig;
    animate-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }

  .aaSlideInUp{
    animation: slideInUp;
    animate-duration: 500ms;
    -webkit-animation-duration: 500ms;
    animation-timing-function: ease-in;
    -webkit-animation-timing-function: ease-in;
  }

  .aaSlideInUp1s{
    animation: fadeInUpBig;
    animate-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }

  .aaSlideInUp2s{
    animation: fadeInUpBig;
    animate-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }


  .aaFadeIn1s{
    animation: fadeIn;
    animate-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }

  .aaFadeIn{
    animation: fadeIn;
    animate-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }

  .aaFadeOut{
    animation: fadeOut;
    animate-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: cubic-bezier(1,.02,.56,1.01);
    -webkit-animation-timing-function: cubic-bezier(1,.02,.56,1.01);
  }

  .aaSlideOutUp{
    animation: slideOutUp 500ms ease-in;
  }

  .aaSlideOutDown{
    animation: slideOutDown 500ms ease-in;
  }

  .aaSlideOutUp200ms{
    animation: slideOutUp 500ms ease;
    animation-delay: 200s;
  }

</style>
<style lang="less">
  .back-home-a{
    color: inherit;
    text-decoration: none;
  }

  .cc-main{
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    &.open{
      overflow-y: hidden;


    }
    &.close {

    }
  }

html,body{
  margin: 0 ;
  padding: 0 ;
  overflow-y: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
