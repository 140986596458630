<template>
    <div class="bc">
        <Header></Header>

        <div id="marx-logo-container" class="aaFadeIn1s wl">
            <div id="canvas" class="curtain__canvas"></div>
            <div class="curtain__container" >
                <img class="curtain__image curtain__image--is-hidden" src="../assets/img/noone.png" data-sampler="planeTexture" crossorigin="anonymous">
            </div>
        </div>
        <div class="ml">
            <img   src="../assets/img/mnoone.png"  >
        </div>

        <HomePIC ></HomePIC>   <!--:imgs="imgs"-->
    </div>
</template>

<script>
  // @ is an alias to /src
  import {nextTick} from 'vue'
  import HomePIC from '@/components/HomePIC.vue'
  import Header from '@/components/Header'
  import {sp} from '@/shaders/sp'
  import {sspinit, sspclear} from "@/shaders/sspinit";
  // import { Curtains } from "vue-curtains";
  // import ImagePlane from "@/components/ImagePlane";

  export default {
    name: 'HomeView',
    components: {
      HomePIC,
      Header,
    },
    data(){
      return{
        show:false,
        curtainsParams:{
          pixelRatio: 1,
        }
      }
    },
    created() {

    },
    mounted() {
      this.$nextTick(function () {
        setTimeout(function () {
          sspinit();
        },200)
      })
    },
    beforeUnmount() {
      console.log('ununununun');
      sspclear()
    }
  }
</script>
<style lang="less">

    body{
        background-color: #ECE8E7;
    }

    #spCanvas{
        width: 1456px;
        height: 153px;
        padding: 0;
        pointer-events: none;
    }

    #marx-logo-container {
        background-color: transparent;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 85px;
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
    }
    #canvas {
        /*position: absolute;*/
        /*left: 50px;*/
        /*right: 50px;*/
        position: absolute;
        //padding-left: 40px;
        //padding-right: 40px;
        width: 1456px;
        height: 153px;

    }
    .curtain__container{
        width: 1456px;
        height: 153px;
    }
    .curtain__image {

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        height: auto;
        display: none;
    }
    .bc{
        /*background-color: #ECE8E7;*/
        .head2{
            max-width: 1316px;
            margin: 0 auto;
            padding-top: 85px;
            padding-left: 50px;
            padding-right: 50px;
            img{
                width: 100%;
            }
        }
        .curtain{
            width: 300px;
            height: 60px;
            img{
                display: none;
            }
        }
        .ml{
            display: none;
            position: fixed;
            left: 0;
            right: 0;
        }
    }
    @media screen and (max-width: 600px){
        .bc{
            .ml{
                display: block;
                padding-top: 131px;
                text-align: center;
                img{
                    width: 90%;

                }
            }
        }
    }
</style>
