import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from "pinia";
import "@/assets/index.less"
import "@/assets/animate.css"

//检测加载动画是否执行完，执行完再启动vue
const checkLoadStatus=()=>{
  if(window.loadShowAll && window.fontLoaded && window.imagesLoaded){
    createApp(App).use(createPinia()).use(router).mount('#app');
    return;
  }
  setTimeout(function () {
    checkLoadStatus()
  },200)
}

checkLoadStatus();

