<template>
  <div class="content aaSlideInUp1s" @scroll="onCScroll($event)">
    <div v-for="(item,index) in menu.homeData" class="item-c" :id="'picItem'+ index" >
      <div class="item-c-media">
<!--        <ImagesFade v-if="item.hometype==='图像'" :images="getImages(item)" :mimages="getMImages(item)"></ImagesFade>-->
        <div v-if="item.hometype==='视频'" class="video-c" style="width: 100%">
          <video v-if="hData.rnum===1" :src="item.video1" autoplay muted loop></video>
          <video v-else-if="hData.rnum===2" :src="item.video2" autoplay muted loop></video>
          <video v-else :src="item.video3" autoplay muted loop></video>
        </div>
        <div v-if="item.hometype==='视频'" class="video-c-m" style="width: 100%">
          <video v-if="hData.rnum===1" :src="item.mvideo1" autoplay muted loop></video>
          <video v-else-if="hData.rnum===2" :src="item.mvideo2" autoplay muted loop></video>
          <video v-else :src="item.mvideo3" autoplay muted loop></video>
        </div>
      </div>
      <div class="picfont"  v-if="hData.showPicId == ('picItem'+index)">{{item.description}}
      </div>
      <div class="picfont-m" style="white-space:pre-wrap">{{item.description}}
      </div>
    </div>
    <div class="h-bottom">
      <div class="left">
        ©NOONE
      </div>
      <div class="right">
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20025448号-1</a>
      </div>
    </div>
  </div>
</template>

<script setup>

  import {reactive, onMounted} from 'vue'
  import {menuStore} from "@/store/menuStore";
  import ImagesFade from "@/components/ImagesFade.vue"
  const menu = menuStore();

  const props=defineProps({
    imgs:Object
  })
  const hData=reactive({
    rnum:1,  //随机数
    showIndex: 0,
    maxImages: 5,
    showPicId: '',
    picItem: []
  })
  const created=()=>{
    let arr = [1,2,3];
    let tmpNum = 0;
    if(localStorage.getItem('rnum--')){
      arr.splice(Number(localStorage.getItem('rnum--')) - 1, 1);
      tmpNum = Math.floor(Math.random() * 2);
    }else{
      tmpNum = Math.floor(Math.random() * 3);
    }
    hData.rnum = arr[tmpNum];
    localStorage.setItem('rnum--', hData.rnum);
    console.log("hData.rnum,",hData.rnum)
  }

  const getImages=(item)=>{
    let images=[];
    if(item.imgUrl1){
      images.push(item.imgUrl1)
    }
    if(item.imgUrl2){
      images.push(item.imgUrl2)
    }
    if(item.imgUrl3){
      images.push(item.imgUrl3)
    }
    if(item.imgUrl4){
      images.push(item.imgUrl4)
    }
    if(item.imgUrl5){
      images.push(item.imgUrl5)
    }
    return images;
  }

  const getMImages=(item)=>{
    let images=[];
    if(item.mimg1){
      images.push(item.mimg1)
    }
    if(item.mimg2){
      images.push(item.mimg2)
    }
    if(item.mimg3){
      images.push(item.mimg3)
    }
    if(item.mimg4){
      images.push(item.mimg4)
    }
    if(item.mimg5){
      images.push(item.mimg5)
    }
    console.log('images mm mm ', images);
    return images;
  }

  onMounted(()=>{
    for(let i =0; i<menu.homeData.length;i++ ){
      hData.picItem.push(document.getElementById("picItem"+i));
    }
  })

  const onCScroll = (event)=>{
    // console.log('scroll', event.target.scrollTop)
    let hasPicShow = false
    hData.picItem.forEach(item=>{
      console.log('scroll',item.id, item.offsetTop)
      if(item.offsetTop - event.target.scrollTop < 0){
        hData.showPicId = item.id
        hasPicShow = true
      }
    });
    if(!hasPicShow){
      hData.showPicId = '';
    }
  }

  created();
</script>

<style lang="less" scoped>
  .content{
    padding-top: calc(117px + 153px + 85px);
    z-index: 101;
    position: fixed;
    height: calc(100vh - (117px + 153px + 85px));
    overflow-y: auto;
    z-index: 7;
    width: 100vw;
  }
  .imgstyle{
    width: 100%;
  }
  .picfont{
    color: #000000ff;
    font-weight: 400;
    font-family: Source Han Sans CN;
    font-size: 50px;
    text-align: left;
    line-height: 86px;
    padding-left: 49px;
    padding-top: 100px;
    padding-bottom: 726px;
    white-space: pre-wrap; /*显示换行*/
    background-color: #ECE8E7;
  }
  .picfont-m{
    display: none;
  }

  .item-c{
    position: relative;
    .item-c-media{
      z-index: 9;
      position: relative;
      left: 0;
      right: 0;
      padding-bottom: 100vh;
      >div{
        video{
          width: 100%;
          height: auto;
        }
      }
      .video-c{
        display: block;
        img{
          display: none;
        }
        video{
          display: block;
        }
      }
      .video-c-m{
        display: none;
      }
    }
    .picfont{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 8;
    }
  }

  .h-bottom{
    display: flex;
    justify-content: space-between;
    padding-bottom: 47px;
    padding-left: 50px;
    padding-right: 50px;
    color: #000000;
    font-family: TaipeiSansTCBeta;
    font-weight: 700;
    height: 14px;
    font-size: 16px;
    position: relative;
    z-index: 8;
    .right{
      a{
        text-decoration: none;
        color: #000000;
      }
    }
  }

  @media screen and (max-width: 600px) {

    .item-c {
      min-height: 100vh;
      background-color: #ECE8E7;
      .item-c-media {
        padding-bottom: 0;
        .video-c{
          display: none;
        }
        .video-c-m{
          display: block;
        }

        > div {
          video {
            width: 100vw;
            height: auto;
          }
        }
      }
    }

    .h-bottom {
      display: flex;
      justify-content: space-between;
      padding-bottom: 47px;
      padding-left: 20px;
      padding-right: 20px;
      color: #000000;
      font-family: TaipeiSansTCBeta;
      font-weight: 700;
      height: 14px;
      font-size: 16px;
      width: calc(100vw - 40px);
      .left,.right{
        overflow: inherit;
      }
    }

    .content{
      padding-top: calc(117px + 153px);
      height: calc(100vh - (117px + 153px));
      >div{
        >div{
          overflow: hidden;
          display: flex;
          justify-content: center;
        }
      }
      .imgstyle{
        min-height: 710px;
        width: auto;
      }
      .picfont{
        display: none;
      }
      .picfont-m{
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 28px;
        white-space:pre-wrap
      }
    }
  }

</style>
