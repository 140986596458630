<template>
    <div class="menubc">
        <div class="head">
            <transition mode="out-in" enter-active-class="aaSlideInDown400ms"
                        leave-active-class="aaSlideOutUp">
                <a class="headfont left" v-if="menu.open"  href="/en">EN</a>
            </transition>
            <transition mode="out-in" enter-active-class="aaSlideInDown400ms"
                        leave-active-class="aaSlideOutUp">
                <div class="headfont right" v-if="menu.open" @click="closeMenu">关闭</div>
            </transition>
        </div>
        <div class="menu">
            <!--      <transition mode="out-in" enter-active-class="aaSlideInDown"-->
            <!--                  leave-active-class="aaSlideOutUp">-->
            <div>
            <transition mode="out-in" enter-active-class="aaFadeInDown"
                        leave-active-class="aaFadeOutUp">
                <div style="display: inline-block" @click="doPage('/works')" v-if="mData.menuOpen[0]"><span class="m-text">作品</span><span>, </span></div>
            </transition>
            <transition mode="out-in" enter-active-class="aaFadeInDown"
                        leave-active-class="aaFadeOutUp">
                <div style="display: inline-block" @click="doPage('/office')" v-if="mData.menuOpen[1]"><span class="m-text">&nbsp;不一</span><span>, </span></div>
            </transition>
            </div>
            <div>
            <!--        <router-link to="" v-if="menu.open" class="zp"></router-link>-->
            <!--      </transition>-->
            <!--      <transition mode="out-in" enter-active-class="aaSlideInDown"-->
            <!--                  leave-active-class="aaSlideOutUp">-->
            <!--      <router-link to="/office" v-if="menu.open">工作室<span>, </span></router-link>-->
            <!--      </transition>-->
            <transition mode="out-in" enter-active-class="aaFadeInDown"
                        leave-active-class="aaFadeOutUp">
                <div style="display: inline-block" @click="doPage('/touch')" v-if="mData.menuOpen[2]"><span class="m-text">接触</span><span class="m-punctuation">. </span></div>
            </transition>
            </div>
        </div>
        <transition mode="out-in" enter-active-class="aaFadeInRight" leave-active-class="aaFadeOutRight">
            <div class="noone" v-if="mData.menuOpen[3]">
                ©NOONE
            </div>
        </transition>
        <transition mode="out-in" enter-active-class="aaFadeInLeft" leave-active-class="aaFadeOutLeft">
            <div class="menuleft" v-if="mData.menuOpen[3]">
              CREATIVITY SPARKS<br>
                BEAUTY AWES<br>
                WE INSPIRE
            </div>
        </transition>
        <transition mode="out-in" enter-active-class="aaFadeInUp" leave-active-class="aaFadeOutDown">
            <div class="menubottom" v-if="mData.menuOpen[3]" @click="doPage('/')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 967.54 80.66">
                <g id="图层_2" data-name="图层 2">
                  <g id="隔离模式">
                    <path class="cls-1"
                          d="M0,73.58V0L129.19,60.27V.46l13,6.06V80.2c-.69-.3-1.62-.74-2.8-1.31s-3-1.42-5.41-2.56l-9.92-4.67q-6.27-3-15.68-7.35L85.51,53.66Q72,47.39,54.15,39T13,19.82V79.63Z"/>
                    <path class="cls-1"
                          d="M228.84.46H326a23,23,0,0,1,8.65,1.65,22.7,22.7,0,0,1,7.18,4.55,21.65,21.65,0,0,1,4.85,6.78,20,20,0,0,1,1.76,8.32V59.13a19.89,19.89,0,0,1-1.76,8.31,21.54,21.54,0,0,1-4.85,6.78,22.55,22.55,0,0,1-7.18,4.56A23,23,0,0,1,326,80.43H228.84A22.83,22.83,0,0,1,213,74.22a21.51,21.51,0,0,1-4.84-6.78,19.89,19.89,0,0,1-1.76-8.31V21.76a20,20,0,0,1,1.76-8.32A21.62,21.62,0,0,1,213,6.66,22.88,22.88,0,0,1,228.84.46Zm-.11,67.32H325.9a9.24,9.24,0,0,0,3.76-.74,10.63,10.63,0,0,0,3-1.94,8.77,8.77,0,0,0,2.05-2.79,7.71,7.71,0,0,0,.74-3.31V21.55a7.68,7.68,0,0,0-.74-3.31,8.81,8.81,0,0,0-2.05-2.8,9.89,9.89,0,0,0-6.78-2.57H228.73A9.89,9.89,0,0,0,222,15.44a8.66,8.66,0,0,0-2,2.8,7.68,7.68,0,0,0-.74,3.31V59a7.71,7.71,0,0,0,.74,3.31,8.62,8.62,0,0,0,2,2.79A10.63,10.63,0,0,0,225,67,9.24,9.24,0,0,0,228.73,67.78Z"/>
                    <path class="cls-1"
                          d="M435.18.68h97.18a23.08,23.08,0,0,1,15.83,6.21A21.62,21.62,0,0,1,553,13.67,19.79,19.79,0,0,1,554.8,22V59.35A19.75,19.75,0,0,1,553,67.67a21.62,21.62,0,0,1-4.84,6.78,23.08,23.08,0,0,1-15.83,6.21H435.18A22.9,22.9,0,0,1,426.46,79a23.13,23.13,0,0,1-7.12-4.56,21.62,21.62,0,0,1-4.84-6.78,19.75,19.75,0,0,1-1.76-8.32V22a19.78,19.78,0,0,1,1.76-8.32,21.62,21.62,0,0,1,4.84-6.78,23.32,23.32,0,0,1,7.12-4.56A22.9,22.9,0,0,1,435.18.68ZM435.06,68h97.18a9.41,9.41,0,0,0,3.76-.74,10.85,10.85,0,0,0,3-1.94,8.7,8.7,0,0,0,2.05-2.8,7.68,7.68,0,0,0,.74-3.31V21.78a7.68,7.68,0,0,0-.74-3.31,8.7,8.7,0,0,0-2.05-2.8,9.67,9.67,0,0,0-3-1.88,10,10,0,0,0-3.76-.69H435.06a9.93,9.93,0,0,0-3.75.69,9.67,9.67,0,0,0-3,1.88,8.55,8.55,0,0,0-2.05,2.8,7.68,7.68,0,0,0-.74,3.31V59.22a7.68,7.68,0,0,0,.74,3.31,8.55,8.55,0,0,0,2.05,2.8,10.85,10.85,0,0,0,3,1.94A9.4,9.4,0,0,0,435.06,68Z"/>
                    <path class="cls-1"
                          d="M619,73.8V.23L748.21,60.49V.68l13,6.06V80.43c-.69-.3-1.62-.74-2.8-1.31s-3-1.43-5.41-2.56l-9.92-4.67q-6.29-3-15.68-7.35L704.53,53.88q-13.47-6.26-31.36-14.63T632,20.05V79.86Z"/>
                    <path class="cls-1"
                          d="M967.54.23V12.65H825.36V.11Zm0,33.72v12.3H825.36V34ZM825.36,67.56H967.54V80.09H825.36Z"/>
                  </g>
                </g>
              </svg>
                <img src="../assets/img/mlogo.png">
            </div>
        </transition>
    </div>
</template>

<script setup>
  import {onMounted, reactive} from "vue"
  import {useRouter} from "vue-router"
  import {menuStore} from '../store/menuStore'
  const router = useRouter();
  const menu = menuStore();
  onMounted(() => {
    setTimeout(function () {
      mData.menuOpen[0] = true;
      setTimeout(function () {
        mData.menuOpen[1] = true;
      }, 60)
      setTimeout(function () {
        mData.menuOpen[2] = true;
      }, 120)

      setTimeout(function () {
        mData.menuOpen[3] = true;
      }, 400)
    }, 500)
  })

  const mData = reactive({
    menuOpen: [false, false, false, false]
  })
  const doPage = (page)=>{
    router.push(page);
    closeMenu();
  }
  const closeMenu = () => {
    mData.menuOpen[3] = false;
    mData.menuOpen[2] = false;
    setTimeout(function () {
      mData.menuOpen[1] = false;
    }, 60)
    setTimeout(function () {
      mData.menuOpen[0] = false;
    }, 120)
    setTimeout(function () {
      menu.doMenuClose();
    }, 200)
  }
</script>

<style lang="less" scoped>



</style>
