import ssp from './ssp'
let sp = null;
export const sspinit = ()=>{
  sp = new ssp();
  sp.enable(true);
}

export const sspclear = ()=>{
  if(sp) {
    console.log('canvas', document.getElementById("canvas"));
    document.getElementById("canvas").children[0].remove();
    document.body.classList.remove("no-curtains")
      document.querySelector(".curtain__container").classList.remove("curtain__container--is-hidden")
    sp.clear();
    sp = null;
  }
}
